<template>
  <div class="form-payment">
    <ModalHeader
      :left-text="''"
      :middle-text="'Silahkan Lengkapi Data Pembayaran Terpisah'"
      :left-function="directionPage"
      :is-rigt-column="true"
      :right-function="createTransaction"
      :is-disabled="isDisabledProcess()"
      :right-text="'Proses'"
    />
    <b-row
      class="container--split mt-unset justify-content-between mt-5 pt-5"
      style="height: 100vh"
    >
      <b-col lg="6" md="6" cols="12">
        <b-overlay
          :show="is_show_overlay"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <div
            class="container--multiple__payment"
            v-for="(payment, index) in payments"
            :key="index"
          >
            <b-form-group class="custom__form--input mb-2">
              <div
                class="d-flex justify-content-between align-items-center"
                style="margin-bottom: 12px"
              >
                <label class="mb-0"
                  >Pilih Metode Pembayaran {{ index + 1 }}</label
                >
                <b-button
                  class="bg-white p-0"
                  @click="deletePayment(index)"
                  v-if="payments.length > 1"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="21"
                    class="text-danger"
                  />
                </b-button>
              </div>
              <b-form-select
                v-model="payments[index].payment_method"
                :options="options"
                @change="onChangePaymentMethod(index)"
                @input="onChangePaymentMethod(index)"
              />
            </b-form-group>
            <Tunai
              v-if="
                payments &&
                payments.length &&
                payments[index].payment_method == 'tunai'
              "
              :indexNominal="index"
              :formData="formPayload.payments[index]"
              @payloadTunai="(value) => getPayload(value, index)"
            />
            <Deposit
              v-if="
                payments &&
                payments.length &&
                payments[index].payment_method == 'wallet'
              "
              :indexNominal="index"
              :formData="formPayload.payments[index]"
              @payloadDeposit="(value) => getPayloadDeposit(value, index)"
              :isButtonHide="true"
              :isSubmit="false"
              :column="12"
            />
            <Giro
              v-if="
                payments &&
                payments.length &&
                payments[index].payment_method == 'giro'
              "
              :indexNominal="index"
              :formData="formPayload.payments[index]"
              @payloadGiro="(value) => getPayloadGiro(value, index)"
              :isSubmit="false"
              :isButtonHide="true"
              :column="12"
            />
            <TransferBank
              v-if="
                payments &&
                payments.length &&
                payments[index].payment_method == 'transfer'
              "
              :indexNominal="index"
              :formData="formPayload.payments[index]"
              @payloadBank="(value) => getPayloadTransferBank(value, index)"
              @bankAccountDetail="
                (value) => getPayloadBankAccountDetail(value, index)
              "
              :isButtonHide="true"
              :column="12"
            />
            <Kredit
              v-if="
                payments &&
                payments.length &&
                payments[index].payment_method == 'card'
              "
              :indexNominal="index"
              :formData="formPayload.payments[index]"
              @payloadCard="(value) => getPayloadKredit(value, index)"
              :isButtonHide="true"
              :isSubmit="false"
              :column="12"
            />
            <Piutang
              v-if="
                payments &&
                payments.length &&
                payments[index].payment_method == 'piutang'
              "
              :indexNominal="index"
              :formData="formPayload.payments[index]"
              @payloadPiutang="(value) => getPayloadPiutang(value, index)"
              :isButtonHide="true"
              :isSubmit="false"
              :column="12"
            />
            <hr />
          </div>
          <b-button
            class="w-100 mb-5 py-1 bg-white"
            @click="addPayments"
            :disabled="disabledAddPaymentMethod()"
          >
            + Tambah Metode Pembayaran
          </b-button>
        </b-overlay>
      </b-col>
      <b-col lg="6" md="6" cols="12" style="position: relative">
        <div class="container--split__preview">
          <total-transaksi-component />
          <div
            v-if="payments && payments[0].payment_method"
            class="mx-auto preview--detail"
          >
            <h6 class="size14 fw-bold-600 text-dark my-1 mx-auto">
              Metode Pembayaran
            </h6>
            <div
              class="d-flex justify-content-between align-items-center mx-auto mb-1"
              v-for="(payment, index) in payments"
              :key="`preview-${index}`"
            >
              <h6
                :id="`account-name-${index}`"
                class="size14 fw-bold-600 text-dark mb-0"
              >
                {{ paymentMethods(payment.payment_method) }}
              </h6>
              <h6
                :id="`nominal--${index}`"
                class="size14 fw-bold-600 text-dark mb-0"
              >
                {{ 0 | formatAmount }}
              </h6>
            </div>
            <div
              class="d-flex justify-content-between align-items-center mx-auto mb-1"
              v-if="
                parseFloat($route.query.total) +
                  totalAddtionalCost -
                  totalNominal >
                0
              "
            >
              <h6 class="size14 fw-bold-600 text-dark mb-0">Sisa</h6>
              <h6 class="size14 fw-bold-600 text-dark mb-0">
                {{
                  (parseFloat($route.query.total) +
                    totalAddtionalCost -
                    totalNominal)
                    | formatAmount
                }}
              </h6>
            </div>
            <hr />
            <div
              class="d-flex justify-content-between align-items-center mx-auto mb-1"
            >
              <h6 class="size14 fw-bold-600 text-dark mb-0">Total</h6>
              <h6 class="size14 fw-bold-600 text-dark mb-0">
                {{ totalNominal | formatAmount }}
              </h6>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BImg,
  VBToggle,
  BFormSelect,
  BForm,
  BFormGroup,
  BFormInput,
  BOverlay,
  BButton,
} from "bootstrap-vue";
import AppTour from "@core/components/app-tour/AppTour.vue";

import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
  formatRupiah,
} from "@/auth/utils";

import Deposit from "@/components/Payment/Deposit/FormStep1.vue";
import Giro from "@/components/Payment/Giro/FormStep1.vue";
import TransferBank from "@/components/Payment/TransferBank/index.vue";
import Kredit from "@/components/Payment/Kredit/FormStep1.vue";
import Piutang from "@/components/Payment/Piutang/FormStep1.vue";
import Tunai from "@/components/Payment/Tunai.vue";
import ModalHeader from "@/components/ModalHeader.vue";
import TotalTransaksiComponent from "@/components/Transaction/TotalTransaksi.vue";

export default {
  title() {
    return "Pembayaran";
  },
  components: {
    BContainer,
    BCard,
    BImg,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormInput,
    ModalHeader,
    AppTour,
    BFormSelect,
    BForm,
    Deposit,
    Giro,
    TransferBank,
    Kredit,
    Piutang,
    Tunai,
    TotalTransaksiComponent,
    BButton,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  computed: {
    ...mapState({
      formAdditionalCost: (state) => state.cart.formAdditionalCost,
      totalTransaksi: (state) => state.cart.totalTransaksi,
      customerUuid: (state) => state.customer.customer.uuid,
    }),
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
      formatRupiah,
    };
  },
  data() {
    return {
      bank_account: null,
      total: localStorage.getItem("cash_total_payment"),
      userData: JSON.parse(localStorage.getItem("userData")),
      customer_uuid: localStorage.getItem("customer_uuid") || false,
      formPayload: {
        payments: [
          {
            payment_method: null,
            cash_total_payment: "",
          },
        ],
      },
      is_full_payment: JSON.parse(localStorage.getItem("is_full_payment")),
      payments: [
        {
          payment_method: null,
        },
      ],
      watchs: [],
      test: [],
      is_show_overlay: false,
      totalNominal: 0,
      totalAddtionalCost: 0,
      options: [
        { value: null, text: "Pilih metode pembayaran" },
        {
          text: "Tunai",
          value: "tunai",
        },
        {
          text: "Transfer Bank",
          value: "transfer",
        },
        {
          text: "Kredit atau Debit",
          value: "card",
        },
        {
          text: "Giro",
          value: "giro",
        },
        {
          text: "Deposit",
          value: "wallet",
        },
        {
          text: "Piutang",
          value: "piutang",
          disabled: false,
        },
      ],
      swalOptions: {
        title: "Warning!",
        text: "",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      },
      isDisabled: false,
    };
  },
  watch: {
    watchs: {
      handler: _.debounce(function () {
        this.validationAmount();
      }, 300),
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.totalAddtionalCost = 0;

    const additional_costs = JSON.parse(
      localStorage.getItem("additional_cost")
    );
    const payload_transaksi = JSON.parse(
      localStorage.getItem("payloadTransaksi")
    );
    const payload_delivery = JSON.parse(
      localStorage.getItem("payloadDelivery")
    );

    this.formPayload.date = this.$route.query.date;

    for (let index = 0; index < additional_costs.length; index++) {
      const element = additional_costs[index];
      this.totalAddtionalCost += parseFloat(
        preformatFloat(element.additional_fee_amount)
      );
    }

    this.formPayload = {
      ...this.formPayload,
      ...payload_transaksi,
      ...payload_delivery,
      ...additional_costs,
    };

    this.updatedOptions();

    if (this.$route.query.data) {
      const decodeData = JSON.parse(decodeURIComponent(this.$route.query.data));
      this.is_show_overlay = true;
      setTimeout(() => {
        this.payments = decodeData;
        this.is_show_overlay = false;
        for (let index = 0; index < decodeData.length; index++) {
          const element = decodeData[index];
          if (document.getElementById(`nominal--${index}`)) {
            setTimeout(() => {
              document.getElementById(`nominal--${index}`).innerHTML = `Rp ${
                !element.cash_total_payment.match(".")
                  ? element.cash_total_payment
                  : element.cash_total_payment
              }`;
            }, 300);
          }
          this.bankAccountName();
        }
      }, 500);
    }
  },
  methods: {
    bankAccountName() {
      for (let index = 0; index < this.payments.length; index++) {
        const element = this.payments[index];
        setTimeout(() => {
          const accountName = document.getElementById(`account-name-${index}`);
          if (element.payment_method == "transfer" && accountName) {
            accountName.innerHTML = `Transfer ke ${
              element.account_detail?.bank?.name || ""
            }`;
          }
        }, 500);
      }
    },
    onChangePaymentMethod(index) {
      this.formPayload.payments[index] = {
        ...this.payments[index],
        cash_total_payment: "",
      };

      for (
        let indexPayment = 0;
        indexPayment < this.formPayload.payments.length;
        indexPayment++
      ) {
        const element = this.formPayload.payments[indexPayment];
        if (element.payment_method == "piutang") {
          this.formPayload.payments[indexPayment] = {
            ...element,
            payment_customer_uuid: this.customer_uuid || "",
            piutang_tempo: localStorage.getItem("terms_of_payment_day") || "",
          };
        }
      }

      this.validationAmount();

      setTimeout(() => {
        if (document.getElementById(`nominal--${index}`)) {
          document.getElementById(`nominal--${index}`).innerHTML = "Rp 0";
        }
      }, 300);
    },
    paymentMethods(method) {
      const paymentMapping = {
        tunai: "Tunai",
        card: "Debit/Kredit",
        transfer: "Transfer",
        giro: "Giro",
        wallet: "Deposit",
        piutang: "Piutang",
      };

      return paymentMapping[method] || "-";
    },
    addPayments() {
      this.payments.push({
        payment_method: null,
      });
      this.formPayload.payments.push({
        payment_method: null,
      });
      this.updatedOptions();
    },
    updatedOptions() {
      const hasCustomerUuid = !!this.customer_uuid;

      const tunaiCount = this.formPayload.payments.filter(
        (payment) => payment.payment_method === "tunai"
      ).length;

      const updateOptions = this.options.map((option) => {
        if (option.value === "piutang" || option.value === "wallet") {
          return { ...option, disabled: !hasCustomerUuid };
        }
        if (option.value === "tunai") {
          return { ...option, disabled: tunaiCount >= 1 };
        }
        return option;
      });

      this.options = updateOptions;
    },
    validationAmount() {
      const payments = this.formPayload.payments;
      const total = Object.values(payments).reduce((acc, element) => {
        const cashTotalPayment =
          parseFloat(preformatFloat(element.cash_total_payment)) || 0;
        return acc + cashTotalPayment;
      }, 0);
      this.totalNominal = total;
    },
    validateNominal(nominal, limit) {
      if (
        !this.formPayload.payments.some((el) => el.payment_method == "tunai") &&
        nominal > limit
      ) {
        this.swalOptions.text =
          "Jumlah nominal tidak boleh lebih dari total belanja";
      } else if (nominal < limit) {
        this.swalOptions.text =
          "Jumlah nominal tidak boleh kurang dari total belanja";
      } else {
        return {
          code: 200,
          messages: "Sukses",
        };
      }
      // Misal harga total 230.000, user bayar pake tunai sebesar
      this.showWarningMessage();

      return {
        code: 400,
        messages: "Galat",
      };
    },
    showWarningMessage() {
      this.$swal(this.swalOptions);
    },
    showAlertValidation() {
      const totalNominal = this.totalNominal;

      let res = this.validateNominal(
        totalNominal,
        parseFloat(this.$route.query.total) + this.totalAddtionalCost
      );

      return res;
    },
    async createTransaction() {
      
      if (this.formPayload.delivery_pickup === "pickup") {
        delete this.formPayload.receiver_name;
        delete this.formPayload.receiver_phone_number;
        delete this.formPayload.receiver_phone_number_country;
        delete this.formPayload.receiver_address;
        delete this.formPayload.delivery_date;
      }
      // this.formPayload.receiver_phone_number_country =
      //   this.formPayload.selectedCountry.country_code;

      delete this.formPayload.cash_total_payment;
      delete this.formPayload.onlyRecheiverPhone;
      delete this.formPayload.selectedCountry;

      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin melakukan Transaksi ini?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          let res = await this.showAlertValidation();
          this.is_show_overlay = true;
          if (res.code == 200) {
            this.$store.commit("checkout/setIsLoading", true);
            let form = this.preparePayload();
            this.$store
              .dispatch("checkout/submitCheckout", {
                uuid: localStorage.getItem("cartUuid"),
                payload: form,
              })
              .then((result) => {
                this.is_show_overlay = false;
                this.$store.commit("formWizard/setStep", 1);
                const item = result.data.data;
                this.$router.push({
                  name: "payment.success",
                  query: {
                    uuid: item.uuid,
                    cash_change: item.cash_change,
                    total: item.total,
                    amount: this.$route.query.amount_dp || 0,
                    type_payment: this.$route.query.type_payment,
                  },
                });

                const itemsToRemove = [
                  "cartUuid",
                  "customer_type",
                  "customer_uuid",
                  "cash_total_payment",
                  "payment_method",
                  "price",
                  "poSO",
                  "payloadTransaksi",
                ];

                for (const item of itemsToRemove) {
                  localStorage.removeItem(item);
                }

                this.$store.commit("checkout/setIsLoading", false);
              })
              .catch((err) => {
                this.is_show_overlay = false;
                this.$store.commit("checkout/setIsLoading", false);
                // Handle errors
              });
          } else {
            this.is_show_overlay = false;
          }
        }
      });
    },
    isDisabledProcess() {
      const hasNonCashPayment = this.formPayload.payments.some(
        (el) => el.payment_method !== "tunai"
      );
      const hasExcessivePayment = this.formPayload.payments.some(
        (el) =>
          parseFloat(preformatFloat(el.cash_total_payment)) >
          parseFloat(this.$route.query.total) + this.totalAddtionalCost
      );

      if (!hasNonCashPayment) {
        return false;
      }
      return hasNonCashPayment && hasExcessivePayment;
    },
    disabledAddPaymentMethod() {
      const { $route, totalAddtionalCost, totalNominal, formPayload } = this;
      const total = parseFloat($route.query.total) + totalAddtionalCost;

      return (
        total - totalNominal <= 0 ||
        formPayload.payments.some((el) => el.cash_total_payment == "")
      );
    },
    deletePayment(index) {
      let payments = JSON.parse(localStorage.getItem("payments"));
      const decoy = payments.splice(index, 1);

      this.formPayload.payments.splice(index, 1);
      this.payments.splice(index, 1);

      this.is_show_overlay = true;

      setTimeout(() => {
        this.formPayload.payments = payments;
        this.is_show_overlay = false;
      }, 500);

      this.updatedOptions();
    },
    preparePayload() {
      this.formPayload.customer_uuid = localStorage.getItem("customer_uuid");
      const arrPayments = Object.values(this.formPayload.payments);

      const form = new FormData();
      for (const key in this.formPayload) {
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (key !== "additional_fees" && key !== "payments") {
            form.append(key, this.formPayload[key]);
          }
          if (key === "payments") {
            arrPayments.forEach((element, index) => {
              element.payment_customer_uuid = this.customer_uuid;
              for (const keyPayment in element) {
                if (
                  Object.hasOwnProperty.call(element, keyPayment) &&
                  element[keyPayment]
                ) {
                  const item = element[keyPayment];
                  form.append(
                    `payments[${index}][${
                      keyPayment === "type"
                        ? "payment_method"
                        : keyPayment === "cash_total_payment"
                        ? "amount"
                        : keyPayment
                    }]`,
                    item === "tunai"
                      ? "cash"
                      : keyPayment === "cash_total_payment"
                      ? parseFloat(preformatFloat(item))
                      : item
                  );
                }
              }
            });
          }
          if (key === "additional_fees") {
            for (const keySecond in this.formPayload[key]) {
              if (
                this.formPayload[key].hasOwnProperty(keySecond) &&
                this.formPayload[key][keySecond] != null
              ) {
                for (const keyThird in this.formPayload[key][keySecond]) {
                  if (
                    Object.hasOwnProperty.call(
                      this.formPayload[key][keySecond],
                      keyThird
                    )
                  ) {
                    const element = this.formPayload[key][keySecond][keyThird];
                    form.append(
                      `additional_fees[${keySecond}][${keyThird}]`,
                      keyThird === "additional_fee_note"
                        ? element
                        : parseFloat(preformatFloat(element))
                    );
                  }
                }
              }
            }
          }
        }
      }
      return form;
    },
    updatePayment(value, index, paymentMethod) {
      this.watchs.push(value);
      this.formPayload.payments[index] = {
        ...value,
        payment_method: paymentMethod,
      };
      const cashTotalPayment = value.cash_total_payment || "0";
      document.getElementById(`nominal--${index}`).innerHTML = `Rp ${
        !cashTotalPayment.includes(".") ? cashTotalPayment : cashTotalPayment
      }`;
      localStorage.setItem(
        "payments",
        JSON.stringify(this.formPayload.payments)
      );
    },
    getPayload(value, index) {
      this.updatePayment.call(this, value, index, "tunai");
    },
    getPayloadDeposit(value, index) {
      this.updatePayment.call(this, value, index, "wallet");
    },
    getPayloadGiro(value, index) {
      this.updatePayment.call(this, value, index, "giro");
    },
    getPayloadTransferBank(value, index) {
      this.updatePayment.call(this, value, index, "transfer");
    },
    getPayloadKredit(value, index) {
      this.updatePayment.call(this, value, index, "card");
    },
    getPayloadPiutang(value, index) {
      this.updatePayment.call(this, value, index, "piutang");
    },
    getPayloadBankAccountDetail(value, index) {
      this.formPayload.payments[index] = {
        ...this.formPayload.payments[index],
        account_detail: {
          ...value,
        },
      };
      this.payments[index] = {
        ...this.payments[index],
        account_detail: {
          ...value,
        },
      };
      this.bankAccountName();
      // this.updatePayment.call(this, value, index, 'transfer');
    },
    directionPage() {
      // const queryParams = {
      //   date: this.$route.query.date,
      //   bonus: this.$route.query.bonus,
      //   total: this.$route.query.total,
      //   deliverypickup: this.$route.query.deliverypickup,
      //   po_so_number: this.$route.query.po_so_number,
      //   type_payment: this.$route.query.type_payment,
      //   amount_dp: this.$route.query.amount_dp,
      //   data: encodeURIComponent(JSON.stringify(this.formPayload.payments)),
      //   seller: this.$route.query.seller
      // };
      history.back();
      // this.$router.push({ name: 'payment.index', query: queryParams })
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/tour.scss";

html,
body,
#app {
  background-color: #fff !important;
}

.p-2.bg-gray-2 {
  background-color: #fff !important;
}

.pt-5.bg-gray-2 {
  background-color: unset !important;
}

.mt-unset {
  .mt-3 {
    margin-top: unset !important;
  }
}

// .bg-white {
//   background-color: #f6f7fa !important;
// }

.v-tour {
  .v-step {
    background-color: #334155;

    .v-step__arrow {
      border-color: #334155;
    }
  }
}

.form-payment {
  .image-box {
    width: 46px;
    height: 46px;
  }
  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
  .container--multiple__payment {
    .container {
      padding: unset;

      .row {
        margin: unset;

        .col-12 {
          padding: unset;
        }
        .col-7 {
          padding-right: unset;
        }
        .col-8 {
          padding-left: unset;
        }
        .col-5 {
          padding-left: unset;
        }
        .col-4 {
          padding-right: unset;
        }
      }
    }
  }

  .container--split__preview {
    position: sticky;
    top: calc(50% - 80px);
    left: calc(50% - 80px);

    .preview--detail {
      width: 75%;

      @media screen and (max-height: 799px) and (orientation: landscape) {
        width: 100%;

        .size14 {
          font-size: 12px !important;
        }
      }
    }
  }
}

.container--split {
  margin: 0 125px;

  @media screen and (max-width: 992px) {
    margin: 0 100px;
  }

  @media screen and (max-width: 800px) {
    margin: 0 50px;
  }

  @media screen and (max-width: 600px) {
    margin: 0 80px;
  }

  @media screen and (max-width: 480px) {
    margin: 0 30px;
  }
}
</style>
